@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
  .menu_tab {
    @apply text-gray-800 cursor-pointer select-none;
  }
  .active_menu_tab {
    @apply px-4 py-2 text-white select-none;
  }
}

.orderContainer::-webkit-scrollbar,
body::-webkit-scrollbar {
  display: none;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: #f0f0f0;
  color: #333;
}

.Navbar, .Navbar * {
  border: none !important;
  outline: none !important;
}

/* h1, h2, h3, h4, h5, h6 {
  font-family: 'Orbitron', sans-serif;
} */