/* Food Item Button */
.button {
  background-color: #D0AE64;
  color: rgba(243, 237, 187, 0.815);
  width: 50%;
  border: #D0AE64 1px solid;
  border-radius: 7px;
  padding: 5px;
}

.button:hover{
  color: white;
  transition: 0.2s ease-in;
}

/* Active Menu */
.active_menu_tab {
  background-color: #272525;
}
  
.bg-custom-active {
  background-color: #272525;
}

.home-container {
  position: relative;
}

/* Banner Styling */

.banner-image {
  background-size: cover;
  background-position: center center;
  background-image: url('../../../assets/banner1.jpg');
  width: 100%;
  height: 100vh;
  position: relative; /* Add this to position inner elements */
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 85px;
  overflow-x: hidden;
}

.banner-image::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(26, 25, 25, 0.648); /* Adjust the opacity here */
  z-index: 1; /* Ensure this covers the background image but stays behind other content */
}

.banner-text {
  position: absolute;
  top: 37%; /* Adjust the percentage as needed */
  left: 12.5%; /* Adjust the percentage as needed */
  color: white;
  animation: fadeInLeft 1.5s ease-in-out;
  z-index: 2;
}

.banner-text h2 {
  font-size: 2.9em; /* Adjust the size as needed */
  margin: 0;
}

.banner-text p {
  font-size: 1.1em; /* Adjust the size as needed */
  margin: 10px 0 0 0;
  max-width: 600px; /* Optional: limit the width of the paragraph */
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Menu Background */
.menu-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-image: linear-gradient(rgba(12, 12, 12, 0.990), rgba(12, 12, 12, 0.990)), 
  url('../../../assets/foodpattern.jpg'); */
  background: #080a0e;
  background-size: cover;
  z-index: -2;
  /* background: #1e1e1e; */
}